/*
 * This source is generated by bin/gen/licon.py. Run licon.py after changing public/font/lichess.sfd
 *
 * Constant names and values are pulled from private use characters defined in the sfd file.
 *
 * Character names can be edited in fontforge's "glyph info" dialog, or by editing the StartChar: line that
 * begins each character chunk in lichess.sfd
 *
 * To make these characters visible in your editor, install the lichess.ttf font (which is also generated by
 * licon.py) and then add it to your editor's font list.
 */

$licon-Tools: ''; // e000
$licon-Bullseye: ''; // e001
$licon-Agent: ''; // e002
$licon-Mic: ''; // e003
$licon-BarChart: ''; // e004
$licon-InfoCircle: ''; // e005
$licon-ScreenDesktop: ''; // e006
$licon-PhoneMobile: ''; // e007
$licon-Multiboard: ''; // e008
$licon-HeartOutline: ''; // e009
$licon-FlagRacingKings: ''; // e00a
$licon-Crazyhouse: ''; // e00b
$licon-Tshirt: ''; // e00c
$licon-Heart: ''; // e00d
$licon-RadioTower: ''; // e00e
$licon-BellOutline: ''; // e00f
$licon-Disc: ''; // e010
$licon-Wings: ''; // e011
$licon-DiscOutline: ''; // e012
$licon-Handset: ''; // e013
$licon-ArrowThruApple: ''; // e014
$licon-CautionTriangle: ''; // e015
$licon-Link: ''; // e016
$licon-Rabbit: ''; // e017
$licon-NodeBranching: ''; // e018
$licon-Gear: ''; // e019
$licon-Reload: ''; // e01a
$licon-DieSix: ''; // e01b
$licon-FlagKingHill: ''; // e01c
$licon-FlameBlitz: ''; // e01d
$licon-Feather: ''; // e01e
$licon-Turtle: ''; // e01f
$licon-FlagChessboard: ''; // e020
$licon-ArcheryTarget: ''; // e021
$licon-ThreeCheckStack: ''; // e022
$licon-UploadCloud: ''; // e023
$licon-ExternalArrow: ''; // e024
$licon-AnalogTv: ''; // e025
$licon-RssFeed: ''; // e026
$licon-StudyBoard: ''; // e027
$licon-Shield: ''; // e028
$licon-InkQuill: ''; // e029
$licon-Target: ''; // e02a
$licon-Crown: ''; // e02b
$licon-LineGraph: ''; // e02c
$licon-GraduateCap: ''; // e02d
$licon-PaperAirplane: ''; // e02e
$licon-ZoomIn: ''; // e02f
$licon-Expand: ''; // e030
$licon-Atom: ''; // e031
$licon-List: ''; // e032
$licon-Antichess: ''; // e033
$licon-Microscope: ''; // e034
$licon-ChasingArrows: ''; // e035
$licon-CrownElite: ''; // e036
$licon-Funnel: ''; // e037
$licon-Checkmark: ''; // e038
$licon-InternalArrow: ''; // e039
$licon-PlayTriangle: ''; // e03a
$licon-GreaterThan: ''; // e03b
$licon-LessThan: ''; // e03c
$licon-DiscBig: ''; // e03d
$licon-DiscBigOutline: ''; // e03e
$licon-X: ''; // e03f
$licon-ArrowDownRight: ''; // e040
$licon-ArrowUpRight: ''; // e041
$licon-PlusButton: ''; // e042
$licon-MinusButton: ''; // e043
$licon-Fire: ''; // e044
$licon-DownTriangle: ''; // e045
$licon-UpTriangle: ''; // e046
$licon-Bullet: ''; // e047
$licon-Swords: ''; // e048
$licon-JumpLast: ''; // e049
$licon-JumpFirst: ''; // e04a
$licon-JumpNext: ''; // e04b
$licon-JumpPrev: ''; // e04c
$licon-Pause: ''; // e04d
$licon-Hamburger: ''; // e04e
$licon-Globe: ''; // e04f
$licon-Book: ''; // e050
$licon-BarGraph: ''; // e051
$licon-Keypad: ''; // e052
$licon-Berserk: ''; // e053
$licon-Padlock: ''; // e054
$licon-FlagOutline: ''; // e055
$licon-BubbleSpeech: ''; // e056
$licon-BubbleConvo: ''; // e057
$licon-Envelope: ''; // e058
$licon-Group: ''; // e059
$licon-Trophy: ''; // e05a
$licon-ThumbsUp: ''; // e05b
$licon-Back: ''; // e05c
$licon-CautionCircle: ''; // e05d
$licon-NotAllowed: ''; // e05e
$licon-RandomColor: ''; // e05f
$licon-Pencil: ''; // e060
$licon-Cogs: ''; // e061
$licon-Tag: ''; // e062
$licon-Clock: ''; // e063
$licon-Trash: ''; // e064
$licon-User: ''; // e065
$licon-StarOutline: ''; // e066
$licon-Star: ''; // e067
$licon-MoreTriangle: ''; // e068
$licon-Eye: ''; // e069
$licon-Power: ''; // e06a
$licon-Download: ''; // e06b
$licon-Search: ''; // e06c
$licon-Forward: ''; // e06d
$licon-UltraBullet: ''; // e06e
$licon-Storm: ''; // e06f
$licon-Clipboard: ''; // e070
$licon-Cancel: ''; // e071
$licon-Ibeam: ''; // e072
$licon-Voice: ''; // e073
$licon-Mask: ''; // e074
$licon-Move: ''; // e075
$licon-OneHalf: ''; // e076
$licon-Mute: ''; // e077
$licon-AccountCircle: ''; // e078
$licon-Logo: ''; // e079
$licon-Switch: ''; // e07a
