@import 'board-2d';

cg-board {
  @extend %box-shadow, %abs-100;

  top: 0;
  left: 0;
  user-select: none;
  line-height: 0;

  .manipulable & {
    cursor: pointer;
  }
}

cg-board::before {
  content: '';
  left: 0;
  width: 100%;
  background-size: cover;
  position: absolute;
  border-radius: inherit;
  box-shadow: inherit;

  body:not(.simple-board) & {
    filter: brightness(calc(var(---board-brightness) / 100)) hue-rotate(calc(var(---board-hue) * 3.6deg));

    @include if-transp {
      opacity: calc(var(---board-opacity) / 100);
      filter: hue-rotate(calc(var(---board-hue) * 3.6deg));
    }
  }
}

square {
  position: absolute;
  top: 0;
  left: 0;
  width: 12.5%;
  height: 12.5%;
  pointer-events: none;

  &.move-dest {
    background: radial-gradient(rgba(20, 85, 30, 0.5) 19%, rgba(0, 0, 0, 0) 20%);
    pointer-events: auto;
  }

  &.premove-dest {
    background: radial-gradient(rgba(20, 30, 85, 0.5) 19%, rgba(0, 0, 0, 0) 20%);
    pointer-events: auto;
  }

  &.oc.move-dest {
    background: radial-gradient(transparent 0%, transparent 79%, rgba(20, 85, 0, 0.3) 80%);
  }

  &.oc.premove-dest {
    background: radial-gradient(transparent 0%, transparent 79%, rgba(20, 30, 85, 0.2) 80%);
  }

  body[data-board='green'] .is2d &.last-move,
  body[data-board='green-plastic'] .is2d &.last-move,
  body[data-board='marble'] .is2d &.last-move {
    background-color: rgba(0, 155, 199, 0.41);
  }

  &.last-move {
    will-change: transform;
    background-color: rgba(155, 199, 0, 0.41);

    body[data-board='horsey'] .is2d &:not(.move-dest) {
      background: url(../images/board/horsey.last-move.png);
      background-size: cover;
    }
  }

  &.check {
    background: radial-gradient(
      ellipse at center,
      rgba(255, 0, 0, 1) 0%,
      rgba(231, 0, 0, 1) 25%,
      rgba(169, 0, 0, 0) 89%,
      rgba(158, 0, 0, 0) 100%
    );
  }

  &.selected {
    background-color: rgba(20, 85, 30, 0.5);

    body[data-board='horsey'] .is2d & {
      background: url(../images/board/horsey.selected.png);
      background-size: cover;
    }
  }

  &.current-premove {
    background-color: rgba(20, 30, 85, 0.5) !important;

    body[data-board='horsey'] .is2d & {
      background: url(../images/board/horsey.current-premove.png);
      background-size: cover;
    }
  }

  &.move-dest:hover {
    background: rgba(20, 85, 30, 0.3);

    body[data-board='horsey'] .is2d & {
      background: url(../images/board/horsey.move-dest.png);
      background-size: cover;
    }
  }

  &.premove-dest:hover {
    background: rgba(20, 30, 85, 0.2);
  }

  &.bh1 piece {
    opacity: 0.98;
  }
}

piece {
  position: absolute;
  top: 0;
  left: 0;
  width: 12.5%;
  height: 12.5%;
  background-size: cover;
  z-index: $z-cg__piece-2;
  will-change: transform;
  pointer-events: none;

  &.dragging {
    cursor: move;
    z-index: $z-cg__piece_dragging-204 !important;
  }

  &.anim {
    z-index: $z-cg__piece_anim-3;
  }

  &.fading {
    z-index: $z-cg__piece_fading-1;
    opacity: 0.5;
  }

  &.ghost {
    opacity: 0.3;
  }
}

cg-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
  right: 0;
}

cg-container .cg-shapes,
cg-container .cg-custom-svgs,
cg-auto-pieces {
  overflow: visible;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

cg-container .cg-shapes {
  opacity: 0.6;
  overflow: hidden;
  z-index: $z-cg__svg_cg-shapes-2;
}

cg-container .cg-custom-svgs {
  z-index: $z-cg__svg_cg-custom-svgs-4;
}

cg-container .cg-custom-svgs svg {
  overflow: visible;
}

cg-auto-pieces {
  z-index: $z-cg__cg-auto-pieces-2;

  piece {
    opacity: 0.3;
  }
}

html:not(.transp) body:not(.simple-board) {
  &.coords-in coord,
  cg-board piece,
  cg-board square,
  .cg-custom-svgs {
    filter: brightness(calc(0.3 + 0.0059 * min(120, var(---board-brightness))));
    //hue-rotate(calc(var(---board-hue) * 3.6deg));
  }
}

html.transp body:not(.simple-board) cg-board {
  piece,
  square {
    opacity: calc(min(1, 0.5 + var(---board-opacity) / 100));
    //filter: hue-rotate(calc(var(---board-hue) * 3.6deg));
  }
}

coords.squares {
  text-transform: uppercase;
  text-align: right;
  flex-flow: column-reverse;
  height: 100%;
  width: 12.5%;

  &.black {
    flex-flow: column;
  }

  &.left {
    text-align: left;
  }

  coord {
    padding: 6% 4%;
  }

  &.rank2 {
    transform: translateX(100%);
  }

  &.rank3 {
    transform: translateX(200%);
  }

  &.rank4 {
    transform: translateX(300%);
  }

  &.rank5 {
    transform: translateX(400%);
  }

  &.rank6 {
    transform: translateX(500%);
  }

  &.rank7 {
    transform: translateX(600%);
  }

  &.rank8 {
    transform: translateX(700%);
  }
}
